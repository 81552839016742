@use 'variables';

.payment-return {
    margin: 40px 0px;
    .section {
        display: flex;
        justify-content: center;
        margin: 0px -10px;

        &__box {
            padding: 50px 30px;
            border-radius: 4px;
            box-sizing: border-box;
            border: 1px solid #e2e2e2;
            max-width: 500px;
        }

        &__title {
            display: flex;
            text-align: center;
            justify-content: center;
            flex-wrap: wrap;
            p {
                flex: 0 0 100%;
                margin-bottom: 10px;
                font-family: 'Exo', sans-serif;
                font-size: 28px;
                font-style: italic;
                line-height: 1.5;
                font-weight: 900;
                text-transform: uppercase;
            }

            span {
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                @include variables.regular-font;
            }
        }

        &__successImage {
            display: flex;
            justify-content: center;
            margin: 40px 0px;
            img {
                max-width: 50px;
                max-height: 50px;
            }
        }

        &__buttons {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }

        &__button {
            padding: 12px 20px;
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            margin: 10px 10px;
            background-color: #fff;
            display: flex;
            flex: 0 0 auto;
            @include variables.bold-font;
            &:hover {
                background-color: #000;
                color: #fff;
                transition: all 0.1s;
                border: 1px solid #000;
            }
        }
    }
}
